@media (width >= 1024px) {
  .content {
    padding: 32px;

    background-image: url(./../../../img/bg.jpg);
    background-repeat: no-repeat;
    background-size: 450px;
    background-position: right 150px;
  }
}
