@media (width < 1024px) {
  .nav-cnt {
    margin-top: 8px;
    border-radius: 8px;
  }

  .nav-list {
    --nav-element-height: 38px;
  }

  .nav-lk {
    padding: 4px;

    font-size: 14px;

    &.small {
      font-size: 12px;
    }
  }

  .nav__ic {
    margin-right: 2px;
  }
}
