@media (width >= 1024px) {
  .intro-grid {
    max-width: 660px;
  }

  .intro-img__250 {
    width: 250px;
  }

  .intro-img__210 {
    width: 210px;
  }

  .spiral-container {
    position: relative;
    width: 200px;
    height: 200px;
  }
}
