.wallpaper-i__cnt {
  padding: 0;
  margin: 0;
}

.wallpaper-preview__cnt {
  position: relative;
}

.wallpaper-actions {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}

.wallpaper-actions__item:not(:last-child) {
  margin-bottom: 8px;
}

.popover {
  overflow: hidden;
  height: 100%;

  border: 0;
}
