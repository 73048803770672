@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/roboto-regular-webfont.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/roboto-medium-webfont.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/roboto-bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("../../fonts/roboto-italic-webfont.woff2") format("woff2");
}

:root {
  --font-family-text-roboto: "Roboto", sans-serif;
}
