@media (width < 1024px) {
  .wallpaper-i {
    width: 167px;
  }

  .wallpaper-i__img {
    width: 167px;
  }

  .wallpaper-i__title {
    padding: 4px 0 0 0;
    margin: 0;

    font-size: 14px;
  }
}
