@media (width < 1024px) {
  .btn {
    &.circle {
      width: 32px;
      height: 32px;

      font-size: 0;
    }
  }
}
