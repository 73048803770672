.btn {
  font-size: 18px;
  color: var(--button-color);

  background-color: var(--button-bg);
  border: 0;

  cursor: pointer;

  &:hover {
    background-color: var(--button-bg-hover);
  }

  &:active {
    transform: scale(0.96);
  }

  &.circle {
    border-radius: 50%;
  }

  &.normal {
    border-radius: 4px;
  }

  &.small {
    font-size: 12px;
    padding: 4px 8px;
  }

  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
