@media (width < 1024px) {
  .card-cnt {
    flex-direction: column-reverse;
    gap: 12px;
  }

  .card {
    width: 100%;

    & img {
      width: 100%;
    }
  }

  .card-title {
    padding-bottom: 8px;
  }

  .card-actions {
    padding-top: 8px;
  }
}
