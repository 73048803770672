@media (width >= 1024px) {
  .wallpaper-i {
    width: 250px;
  }

  .wallpaper-i__img {
    width: 250px;
  }

  .wallpaper-i__title {
    padding: 4px 0 0 0;
    margin: 0;

    font-size: 16px;
  }
}
