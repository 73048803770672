.card-item {
  display: inline-block;
  box-sizing: border-box;
}

.card-item__lk {
  text-decoration: none;
}

.card-item__cnt {
  margin: 0;
}

.card-item__inner {
  display: inline-block;
  overflow: hidden;
  border-radius: var(--border-radius-size);
}

.card-item__img {
  display: block;
  width: 100%;

  .card-item__lk:hover & {
    transform: scale(1.03);
    transition: transform 0.3s ease-in-out;
  }
}

.card-item__caption {
  padding-top: 4px;
  color: #666;
}
