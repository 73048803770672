.popup input[type="checkbox"] {
  display: none;
}

.popup {
  position: relative;
}

.popup-content {
  display: none;
  padding: 16px 12px;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  background-color: var(--popup-bg);
  border-radius: 8px;
  text-align: center;
}

.popup--download {
  min-width: 130px;
}

.popup-clickable-area {
  position: relative;
  display: inline-block;
}

.popup-close {
  position: absolute;
  top: 0;
  right: 4px;

  cursor: pointer;
  font-size: 20px;
  color: var(--popup-close-bg);
}

input[type="checkbox"]:checked ~ .popup-content {
  display: block;
}

.open-popup-btn {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  cursor: pointer;
}

.popup-close:hover {
  color: var(--popup-close-hover-bg);
}
