.card-cnt {
  display: flex;
  gap: 32px;
}

.card {
  margin: 0;

  border-radius: var(--border-radius-size);
  overflow: hidden;

  & img {
    display: block;
  }
}

.card-title {
  margin: 0;
}
