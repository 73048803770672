@media (width >= 1024px) {
  :root {
    /* Width */

    --sidebar-width: 268px;

    /* Border */

    --border-radius-size: 12px;

    /* Font sizes */

    --site-font-size: 16px;

    --quote-font-size: 20px;

    --content-font-size: 20px;
    --h2-font-size: 28px;

    --footer-title-font-size: 20px;
  }
}
