.logo {
  padding: 10px 0;
  overflow: hidden;
  display: none;

  background-color: var(--logo-bg);
  border-radius: var(--border-radius-size);

  text-align: center;
}

.logo-rain {
  height: var(--header-height);
  background-color: var(--header-bg);
}
