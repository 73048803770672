body {
  margin: 0;
  background-color: var(--site-bg-gray);

  font-size: var(--site-font-size);
  font-style: normal;
  font-weight: 400;
  font-family: var(--font-family-text-roboto);
  color: var(--site-color);
}

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.main {
  overflow-y: auto;
}
