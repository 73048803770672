/*  Colors    */
:root {
  /* Colors */
  --site-bg: #f6c03e;
  --site-bg-white: #fff;
  --site-bg-gray: #f8f7f3;
  --header-bg: #68bac9;
  --site-color: #666;

  --nav-sp-bg: #fff2da;
  --nav-bg: #fff;
  --nav-bg-active: var(--site-bg);
  --nav-color: #917049;

  --logo-bg: #fff;

  --content-color: #333;

  --button-bg: #f1cd1d;
  --button-bg-hover: #f6c03e;
  --button-color: #fff;

  --action-group-bg: #f6c03e;
  --action-group-color: #917049;

  --card-bg: #f8f7f3;

  --popup-bg: #f8f7f3;
  --popup-close-hover-bg: #f6c03e;
  --popup-close-bg: #917049;

  --footer-link-color: var(--nav-color);

  --download-link-locor: #917049;
}
