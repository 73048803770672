@media (width >= 1024px) {
  .nav-cnt {
    margin-top: 20px;
    border-radius: 12px;
  }

  .nav-list {
    --nav-element-height: 56px;
  }

  .nav-lk {
    padding: 16px 12px;

    font-size: 24px;
  }

  .nav__ic {
    margin-right: 12px;
  }
}
