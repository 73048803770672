.inner {
  max-width: 1024px;

  h2 {
    margin: 0;
    padding: 0;

    font-weight: 500;
  }

  p {
    font-size: var(--content-font-size);
    line-height: 1.4;
    color: var(--content-color);
  }
}
