@media (width >= 1024px) {
  .card-cnt {
    gap: 32px;
  }

  .card {
    width: 600px;
    min-height: 500px;

    background-color: var(--card-bg);
  }

  .card-img {
    width: 100%;
    max-width: 600px;
  }

  .card-title {
    padding-bottom: 20px;
  }

  .card-actions {
    padding-top: 20px;
  }
}
