@media (width < 1024px) {
  .footer {
    --footer-shift: 12px;

    grid-column-gap: 4px;
  }

  .footer__list {
    gap: 8px;
  }
}
