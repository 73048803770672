.intro-welcome {
  padding-bottom: 8px;
  font-size: 18px;
}

.intro-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-column-gap: 0px;
  grid-row-gap: 20px;
}

.intro-grid__cell1 {
  grid-area: 1 / 1 / 2 / 4;
}

.intro-grid__cell2 {
  grid-area: 2 / 1 / 3 / 2;
}

.intro-grid__cell3 {
  grid-area: 2 / 2 / 3 / 3;
}

.intro-grid__cell4 {
  grid-area: 2 / 3 / 3 / 4;
}

.intro-grid__cell5 {
  grid-area: 3 / 1 / 4 / 4;
}
