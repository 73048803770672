.header-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;

  width: 100%;
  height: var(--header-height);
  background-color: var(--header-bg);

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -22px;

    width: 100%;
    height: 22px;
    background-image: url("../../../img/header-clouds.png");
    background-size: 110px 22px;
  }
}
