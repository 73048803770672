@media (width < 1024px) {
  :root {
    /* Width */

    --sidebar-width: 100px;

    /* Border */

    --border-radius-size: 8px;

    /* Font sizes */

    --site-font-size: 12px;

    --quote-font-size: 14px;

    --content-font-size: 14px;
    --h2-font-size: 18px;

    --footer-title-font-size: 14px;
  }
}
