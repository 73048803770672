.grid {
  display: grid;
  grid-template-rows: 50px 1fr auto;
  grid-template-columns: var(--sidebar-width) 1fr;

  height: 100vh;
}

.cell1 {
  grid-row-start: 1;
  grid-column-start: 1;

  grid-row-end: 2;
  grid-column-end: 3;
}
.cell2 {
  grid-row-start: 2;
  grid-column-start: 1;

  grid-row-end: 3;
  grid-column-end: 2;
}
.cell3 {
  grid-row-start: 2;
  grid-column-start: 2;

  grid-row-end: 3;
  grid-column-end: 3;
}
.cell4 {
  grid-row-start: 3;
  grid-column-start: 1;

  grid-row-end: 4;
  grid-column-end: 3;
}
